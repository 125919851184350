.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  background-color: white;
  margin-top: -49px;
  margin-bottom: -73px;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}
